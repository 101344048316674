import {
  FETCH_TENANT_SUCCESS,
  FETCH_TENANTS_MODULES_BY_SPECIFICATION_SUCCESS,
  FETCH_TENANTS_SUCCESS,
  FETCH_TENANT_SHARED_SUCCESS,
  FETCH_CONTRACTS_SUCCESS
} from "../actions/types";

const INITIAL_STATE = {
  contractTenants: [],
  tenantModules: [],
  tenantData: {},
  tenantShared: {},
  contracts: [],
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_TENANTS_SUCCESS:
      return { ...state, contractTenants: payload };
    case FETCH_TENANTS_MODULES_BY_SPECIFICATION_SUCCESS:
      return { ...state, tenantModules: payload };
    case FETCH_TENANT_SUCCESS:
      return { ...state, tenantData: payload };
    case FETCH_TENANT_SHARED_SUCCESS:
      return { ...state, tenantShared: payload };
      case FETCH_CONTRACTS_SUCCESS:
        return { ...state, contracts: payload }
    default:
      return state;
  }
};
