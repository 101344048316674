export const CLIENT_AUTH_SUCCESS = "client_auth_success";
export const CLIENT_AUTH_FAIL = "client_auth_fail";
export const REQUEST_RECOVERY_PASSWORD_SUCCESS =
  "request_recovery_password_success";
export const REQUEST_RECOVERY_PASSWORD_FAIL = "request_recovery_password_fail";
export const CREATE_NEW_PASSWORD_SUCCESS = "create_new_password_success";
export const CREATE_NEW_PASSWORD_FAIL = "create_new_password_fail";
export const CREATE_NEW_PASSWORD_TOKEN_FAIL = "CREATE_NEW_PASSWORD_TOKEN_FAIL";
export const FETCH_APP_ROUTES_MENUS_SUCCESS = "fetch_app_routes_menus_success";
export const FETCH_APP_ROUTES_MENUS_FAIL = "fetch_app_routes_menus_fail";
export const FETCH_CURRENT_USER_SUCCESS = "fetch_current_user_success";
export const FETCH_CURRENT_USER_FAIL = "fetch_current_user_fail";
export const CLIENT_LOGOUT_SUCCESS = "client_logout_success";
export const CLIENT_LOGOUT_FAIL = "client_logout_fail";
export const CLEAR_APP_ROUTES_MENUS = "clear_app_routes_menus";
export const FETCH_MODULOS_CUSTOM_SUCCESS = "fetch_modulos_custom_success";
export const FETCH_MODULOS_CUSTOM_FAIL = "fetch_modulos_custom_fail";
export const SET_ACCESS_APP_LINK = "set_access_app_link";
export const CREATE_TENANT_SUCCESS = "create_tenant_success";
export const CREATE_TENANT_FAIL = "create_tenant_fail";
export const FETCH_TENANT_SUCCESS = "FETCH_TENANT_SUCCESS";
export const FETCH_TENANT_SHARED_SUCCESS = "FETCH_TENANT_SHARED_SUCCESS";
export const FETCH_CONTRACTS_SUCCESS = "FETCH_CONTRACTS_SUCCESS";
export const FETCH_TENANT_SHARED_FAIL = "FETCH_TENANT_SHARED_FAIL";
export const FETCH_TENANT_FAIL = "FETCH_TENANT_FAIL";
export const FETCH_TENANTS_SUCCESS = "fetch_tenant_success";
export const FETCH_TENANTS_FAIL = "fetch_tenant_fail";
export const FETCH_TENANTS_MODULES_BY_SPECIFICATION_SUCCESS =
  "fetch_tenants_modules_by_specification_success";
export const FETCH_TENANTS_MODULES_BY_SPECIFICATION_FAIL =
  "fetch_tenants_modules_by_specification_fail";
export const UPDATE_TENANT_SUCCESS = "update_tenant_success";
export const UPDATE_TENANT_FAIL = "update_tenant_fail";
export const SET_USER_ADMINISTRATOR_SUCCESS = "set_user_administrator_success";
export const SET_USER_ADMINISTRATOR_FAIL = "set_user_administrator_fail";
export const FETCH_CONTRACT_USERS_SUCCESS = "fetch_contract_users_success";
export const FETCH_CONTRACT_USERS_FAIL = "fetch_contract_users_fail";
export const FETCH_IMPLANTATION_USERS_SUCCESS =
  "FETCH_IMPLANTATION_USERS_SUCCESS";
export const FETCH_IMPLANTATION_USERS_FAIL = "FETCH_IMPLANTATION_USERS_FAIL";
export const GENERATE_NEW_PASSWORD_SUCCESS = "GENERATE_NEW_PASSWORD_SUCCESS";
export const GENERATE_NEW_PASSWORD_FAIL = "GENERATE_NEW_PASSWORD_FAIL";
export const FETCH_MODULES_BY_CONTRACT_SUCCESS =
  "fecth_modules_by_contract_success";
export const FETCH_MODULES_BY_CONTRACT_FAIL = "fecth_modules_by_contract_fail";
export const CREATE_USER_SUCCESS = "create_user_success";
export const CREATE_USER_FAIL = "create_user_fail";
export const FETCH_USER_TENANTS_SUCCESS = "fetch_user_tenants_success";
export const FETCH_USER_TENANTS_FAIL = "fetch_user_tenants_fail";
export const FETCH_USER_ROLE_SUCCESS = "fetch_user_role_success";
export const FETCH_USER_ROLE_FAIL = "fetch_user_role_fail";
export const FETCH_USER_ACCESS_PERMISSIONS_SUCCESS =
  "fetch_user_access_permissions_success";
export const FETCH_USER_ACCESS_PERMISSIONS_FAIL =
  "fetch_user_access_permissions_fail";
export const CHANGE_USER_ACCESS_PERMISSIONS_SUCCESS =
  "change_user_access_permissions_success";
export const CHANGE_USER_ACCESS_PERMISSIONS_FAIL =
  "change_user_access_permissions_fail";
export const CHANGE_USER_PASSWORD_SUCCESS = "CHANGE_USER_PASSWORD_SUCCESS";
export const CHANGE_USER_PASSWORD_FAIL = "CHANGE_USER_PASSWORD_FAIL";
export const USER_SET_INITIAL_STATE = "USER_SET_INITIAL_STATE";
export const SET_USER_GROUPS_SUCCESS = "SET_USER_GROUPS_SUCCESS";
export const SET_USER_GROUPS_FAIL = "SET_USER_GROUPS_FAIL";
export const DELETE_USER_GROUP_SUCCESS = "DELETE_USER_GROUP_SUCCESS";
export const DELETE_USER_GROUP_FAIL = "DELETE_USER_GROUP_FAIL";
export const CREATE_USER_GROUP_SUCCESS = "CREATE_USER_GROUP_SUCCESS";
export const CREATE_USER_GROUP_FAIL = "CREATE_USER_GROUP_FAIL";
export const FECTH_USER_MODULES_SUCCESS = "FECTH_USER_MODULES_SUCCESS";
export const FECTH_USER_MODULES_FAIL = "FECTH_USER_MODULES_FAIL";
export const FETCH_VISIBILITY_PROFILE_USER_GROUP_FAIL =
  "FETCH_VISIBILITY_PROFILE_USER_GROUP_FAIL";
export const FETCH_VISIBILITY_PROFILE_USER_GROUP_SUCCESS =
  "FETCH_VISIBILITY_PROFILE_USER_GROUP_SUCCESS";
export const FETCH_USER_GROUP_SUCCESS = "FETCH_USER_GROUP_SUCCESS";
export const FETCH_USER_GROUP_FAIL = "FETCH_USER_GROUP_FAIL";
export const DELETE_VISIBILITY_PROFILE_SUCCESS =
  "DELETE_VISIBILITY_PROFILE_SUCCESS";
export const DELETE_VISIBILITY_PROFILE_FAIL = "DELETE_VISIBILITY_PROFILE_FAIL";
export const CREATE_VISIBILITY_PROFILE_SUCCESS =
  "CREATE_VISIBILITY_PROFILE_SUCCESS";
export const CREATE_VISIBILITY_PROFILE_FAIL = "CREATE_VISIBILITY_PROFILE_FAIL";
export const FETCH_USER_GROUP_PERMISSIONS_SUCCESS =
  "FETCH_USER_GROUP_PERMISSIONS_SUCCESS";
export const FETCH_USER_GROUP_PERMISSIONS_FAIL =
  "FETCH_USER_GROUP_PERMISSIONS_FAIL";
export const CHANGE_USER_GROUP_PERMISSIONS_SUCCESS =
  "CHANGE_USER_GROUP_PERMISSIONS_SUCCESS";
export const CHANGE_USER_GROUP_PERMISSIONS_FAIL =
  "CHANGE_USER_GROUP_PERMISSIONS_FAIL";
export const FETCH_SELECTED_USER_SUCCESS = "FETCH_SELECTED_USER_SUCCESS";
export const FETCH_SELECTED_USER_FAIL = "FETCH_SELECTED_USER_FAIL";
export const CLIENT_RESTRICTED_AREA_FAIL = "CLIENT_RESTRICTED_AREA_FAIL";
export const FETCH_BANNERS_SUCCESS = "FETCH_BANNERS_SUCCESS";
export const FETCH_BANNERS_FAIL = "FETCH_BANNERS_FAIL";
export const USER_SEND_EMAIL_CONFIRMATION_SUCCESS =
  "USER_SEND_EMAIL_CONFIRMATION_SUCCESS";
export const USER_SEND_EMAIL_CONFIRMATION_FAIL =
  "USER_SEND_EMAIL_CONFIRMATION_FAIL";
export const USER_CHANGE_EMAIL_SUCCESS = "USER_CHANGE_EMAIL_SUCCESS";
export const USER_CHANGE_EMAIL_FAIL = "USER_CHANGE_EMAIL_FAIL";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAIL = "USER_DELETE_FAIL";
export const TENANT_DELETE_SUCCESS = "TENANT_DELETE_SUCCESS";
export const TENANT_DELETE_FAIL = "TENANT_DELETE_FAIL";

// TEMPORARIO ERP
export const ERP_REQUEST_GET_EMAILS_ERP_SUCCESS =
  "ERP_REQUEST_GET_EMAILS_ERP_SUCCESS";
export const ERP_REQUEST_GET_EMAILS_ERP_FAIL =
  "ERP_REQUEST_GET_EMAILS_ERP_FAIL";
export const ERP_SET_FIRST_ACCESS = "ERP_SET_FIRST_ACCESS";
export const ERP_SET_CLEAR_TOKEN = "ERP_SET_CLEAR_TOKEN";
export const ERP_CHANGE_CURRENT_STEP = "ERP_CHANGE_CURRENT_STEP";
export const ERP_SET_GRID_INSTANCE = "ERP_SET_GRID_INSTANCE";
export const ERP_SET_TOKEN_SENTINELA = "ERP_SET_TOKEN_SENTINELA";
export const ERP_SET_ADMINISTRATOR_EMAILS = "ERP_SET_ADMINISTRATOR_EMAILS";
export const ERP_SET_CODIGO_CLIENTE = "ERP_SET_CODIGO_CLIENTE";
export const ERP_SET_CONTRACT_ADMINISTRATORS =
  "ERP_SET_CONTRACT_ADMINISTRATORS";
// TEMPORARIO ERP

export const ROT_UPDATE_ROUTES_FAIL = "ROT_UPDATE_ROUTES_FAIL";
export const ROT_UPDATE_ROUTES_SUCCESS = "ROT_UPDATE_ROUTES_SUCCESS";

export const GER_SITUACAO_FILA_NFE_SUCCESS = "GER_SITUACAO_FILA_NFE_SUCCESS";
export const GER_SITUACAO_FILA_CTE_SUCCESS = "GER_SITUACAO_FILA_CTE_SUCCESS";
export const GER_SITUACAO_FILA_MT_SUCCESS = "GER_SITUACAO_FILA_MT_SUCCESS";
export const GER_SITUACAO_FILA_MS_SUCCESS = "GER_SITUACAO_FILA_MS_SUCCESS";
export const GER_SITUACAO_FILA_FAIL = "GER_SITUACAO_FILA_FAIL";
export const GER_EMPRESAS_FILA_SUCCESS = "GER_EMPRESAS_FILA_SUCCESS";
export const GER_EMPRESAS_FILA_FAIL = "GER_EMPRESAS_FILA_FAIL";
export const GER_PAUSAR_CONTINUAR_FILA_SUCCESS =
  "GER_PAUSAR_CONTINUAR_FILA_SUCCESS";
export const GER_PAUSAR_CONTINUAR_FILA_FAIL = "GER_PAUSAR_CONTINUAR_FILA_FAIL";
export const GER_FORCAR_EMPRESA_FILA_SUCCESS =
  "GER_FORCAR_EMPRESA_FILA_SUCCESS";
export const GER_FORCAR_EMPRESA_FILA_FAIL = "GER_FORCAR_EMPRESA_FILA_FAIL";
export const GER_REMOVER_FILA_SUCCESS = "GER_REMOVER_FILA_SUCCESS";
export const GER_REMOVER_FILA_FAIL = "GER_REMOVER_FILA_FAIL";
export const GER_ESTATISTICA_SUCCESS = "GER_ESTATISTICA_SUCCESS";
export const GER_ESTATISTICA_FAIL = "GER_ESTATISTICA_FAIL";
export const GER_CAPTCHA_SUCCESS = "GER_CAPTCHA_SUCCESS";
export const GER_CAPTCHA_FAIL = "GER_CAPTCHA_FAIL";
export const GER_FILA_CHAVES_SUCCESS = "GER_FILA_CHAVES_SUCCESS";
export const GER_FILA_CHAVES_FAIL = "GER_FILA_CHAVES_FAIL";
export const GER_CHAVES_ALTERAR_SUCCESS = "GER_CHAVES_ALTERAR_SUCCESS";
export const GER_CHAVES_ALTERAR_FAIL = "GER_CHAVES_ALTERAR_FAIL";
export const GER_ENVIOS_POR_DIA_CHAVES_SUCCESS =
  "GER_ENVIOS_POR_DIA_CHAVES_SUCCESS";
export const GER_ENVIOS_POR_DIA_CHAVES_FAIL = "GER_ENVIOS_POR_DIA_CHAVES_FAIL";
export const GET_CHAVES_SUCCESS = "GET_CHAVES_SUCCESS";
export const GET_CHAVES_FAIL = "GET_CHAVES_FAIL";
export const GER_ESTATISTICA_ADMISSAO_SUCCESS =
  "GER_ESTATISTICA_ADMISSAO_SUCCESS";
