import { lazy } from "react";

const Home = lazy(() => import("../components/Home"));
const Login = lazy(() => import("../components/Login"));
const Logout = lazy(() => import("../components/Logout"));
const NewPassword = lazy(() => import("../components/NewPassword"));
const ForgotPassword = lazy(() => import("../components/ForgotPassword"));
const NotFound = lazy(() => import("../components/NotFound"));
const Tenant = lazy(() => import("../components/Tenant"));
const TenantAdd = lazy(() => import("../components/Tenant/TenantAdd"));
const Configuration = lazy(() => import("../components/Tenant/Configuration"));
const AccessDenied = lazy(() => import("../components/AccessDenied"));
const User = lazy(() => import("../components/User"));
const UserImplantation = lazy(() =>
  import("../components/User/UserImplantation")
);
const UserChangeEmail = lazy(() =>
  import("../components/User/UserChangeEmail")
);
const UserAdd = lazy(() => import("../components/User/UserAdd"));
const UserPermissionTenant = lazy(() =>
  import("../components/User/UserPermissionTenant")
);
const UserPermissionContract = lazy(() =>
  import("../components/User/UserPermissionContract")
);
const ChangePassword = lazy(() => import("../components/ChangePassword"));
const UserGroup = lazy(() => import("../components/UserGroup"));
const UserGroupAdd = lazy(() => import("../components/UserGroup/UserGroupAdd"));
const DirectAccess = lazy(() => import("../components/DirectAccess"));
const UserGroupEdit = lazy(() =>
  import("../components/UserGroup/UserGroupEdit")
);
const VisibilityProfileAdd = lazy(() =>
  import("../components/UserGroup/VisibilityProfileAdd")
);
const VisibilityProfileEdit = lazy(() =>
  import("../components/UserGroup/VisibilityProfileEdit")
);
const Gerenciamento = lazy(() => import("../components/Gerenciamento"));

const FirstAccess = lazy(() => import("../components/FirstAccess"));

const ContractExchange = lazy(() => import("../components/ContractExchange"));
const Settings = lazy(() => import("../components/Settings"));
const Shared = lazy(() => import("../components/Tenant/Shared"));
const RouteComponents = {
  "/": Home,
  "/home": Home,
  "/login": Login,
  "/logout": Logout,
  "/cadastrar-senha": NewPassword,
  "/informe-email": ForgotPassword,
  "/clientes": Tenant,
  "/clientes/adicionar": TenantAdd,
  "/clientes/compartilhar/:id": Shared,
  "/clientes/gerenciar/:id": Configuration,
  "/contratos": ContractExchange,
  "/usuario": User,
  "/usuario/adicionar": UserAdd,
  "/usuario/implantacao": UserImplantation,
  "/usuario/alterar-email/:id": UserChangeEmail,
  "/usuario/permissoes-base/:id": UserPermissionTenant,
  "/usuario/permissoes-contrato/:id": UserPermissionContract,
  "/not-found": NotFound,
  "/acesso-nao-autorizado": AccessDenied,
  "/alterar-senha": ChangePassword,
  "/grupo-usuario": UserGroup,
  "/grupo-usuario/adicionar": UserGroupAdd,
  "/grupo-usuario/editar/:id": UserGroupEdit,
  "/gerenciamentos": Gerenciamento,
  "/autorizar": DirectAccess,
  "/perfil-visibilidade/adicionar": VisibilityProfileAdd,
  "/perfil-visibilidade/editar/:id": VisibilityProfileEdit,
  "/primeiro-acesso": FirstAccess.$$typeof,
  "/configuracoes": Settings,
};

export default RouteComponents;
