import {
  GER_CAPTCHA_SUCCESS,
  GER_EMPRESAS_FILA_FAIL,
  GER_EMPRESAS_FILA_SUCCESS,
  GER_ENVIOS_POR_DIA_CHAVES_SUCCESS,
  GER_ESTATISTICA_SUCCESS,
  GER_FILA_CHAVES_SUCCESS,
  GER_SITUACAO_FILA_CTE_SUCCESS,
  GER_SITUACAO_FILA_MS_SUCCESS,
  GER_SITUACAO_FILA_MT_SUCCESS,
  GER_SITUACAO_FILA_NFE_SUCCESS,
  GET_CHAVES_SUCCESS,
  GER_ESTATISTICA_ADMISSAO_SUCCESS,
} from "../actions/types";

const INITIAL_STATE = {
  empresas: [],
  filaNfe: {
    situacao: "",
    empresas: { executando: 0, esperando: 0, malsucedidas: 0 },
  },
  filaCte: {
    situacao: "",
    empresas: { executando: 0, esperando: 0, malsucedidas: 0 },
  },
  filaMatoGrosso: {
    situacao: "",
    empresas: { executando: 0, esperando: 0, malsucedidas: 0 },
  },
  filaMatoGrossoSul: {
    situacao: "",
    empresas: { executando: 0, esperando: 0, malsucedidas: 0 },
  },
  estatistica: {},
  captcha: { saldoCapMonster: 0, saldoTwoCaptcha: 0, saldoAntiCaptcha: 0 },
  dadosFilaChaves: {
    situacao: "",
    processamentoSimultaneo: 0,
  },
  enviosPorDia: [],
  chavesFila: [],
  estatisticaAdmissao: {
    candidatesByCompany: [],
    candidatesBySituation: [],
    generalCandidates: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GER_SITUACAO_FILA_NFE_SUCCESS:
      return { ...state, filaNfe: payload };
    case GER_SITUACAO_FILA_CTE_SUCCESS:
      return { ...state, filaCte: payload };
    case GER_SITUACAO_FILA_MT_SUCCESS:
      return { ...state, filaMatoGrosso: payload };
    case GER_SITUACAO_FILA_MS_SUCCESS:
      return { ...state, filaMatoGrossoSul: payload };
    case GER_EMPRESAS_FILA_SUCCESS:
      return { ...state, empresas: payload };
    case GER_EMPRESAS_FILA_FAIL:
      return { ...state, empresas: [] };
    case GER_ESTATISTICA_SUCCESS:
      return { ...state, estatistica: payload };
    case GER_CAPTCHA_SUCCESS:
      return { ...state, captcha: payload };
    case GER_FILA_CHAVES_SUCCESS:
      return { ...state, dadosFilaChaves: payload };
    case GER_ENVIOS_POR_DIA_CHAVES_SUCCESS:
      return { ...state, enviosPorDia: payload };
    case GET_CHAVES_SUCCESS:
      return { ...state, chavesFila: payload };

    case GER_ESTATISTICA_ADMISSAO_SUCCESS:
      return { ...state, estatisticaAdmissao: payload };
    default:
      return state;
  }
};
